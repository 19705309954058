import React from "react";

const Newsletter = (props) => {
  return (
    <>
      <script
        async
        src="https://eomail6.com/form/01e811a4-5657-11ed-957b-ddd23c18e614.js"
        data-form="01e811a4-5657-11ed-957b-ddd23c18e614"
      ></script>
    </>
  );
};

export default Newsletter;
